import { Call, Device, TwilioError } from '@twilio/voice-sdk';
import { useState } from 'react';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ApiClient } from './api';

interface IProps {
	onError: (twilioError: TwilioError.TwilioError) => void;
	onIncomingCall: (phoneCallId: string) => void;
}

export const useTwilioDialer = ({ onError, onIncomingCall }: IProps) => {
	const [device, setDevice] = useState<Device>(undefined);
	const [call, setCall] = useState<Call>(undefined);
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [isRinging, setIsRinging] = useState<boolean>(false);
	const [isMuted, updateIsMuted] = useState<boolean>(false);
	const [deviceState, setDeviceState] = useState<Device.State>(undefined);

	const session = useUserSession();

	const createDevice = (token: string) => {
		const newDevice = new Device(token, {
			allowIncomingWhileBusy: false,
			closeProtection: 'You are on a call. Closing this tab will end it.',
		});

		newDevice.on(Device.EventName.Registering, () => {
			console.log('device registering', newDevice.identity);
			setDeviceState(Device.State.Registering);
		});

		newDevice.on(Device.EventName.Registered, () => {
			console.log('device registered', newDevice.identity);
			console.log('device info:', newDevice.edge, newDevice.home, newDevice.state);
			setDeviceState(Device.State.Registered);
		});

		newDevice.on(Device.EventName.Unregistered, () => {
			console.log('device unregistered', newDevice.identity);
			setDeviceState(Device.State.Unregistered);
		});

		return newDevice;
	};

	const addCallListeners = (newCall: Call) => {
		setCall(newCall);
		updateIsMuted(newCall.isMuted());

		newCall.on('accept', callArg => {
			console.log(
				'The incoming call was accepted or the outgoing call media session has finished setting up.',
				callArg
			);
			setIsConnected(true);
			setIsRinging(false);
		});

		newCall.on('reject', () => {
			console.log('The call was rejected.');
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('disconnect', callArg => {
			console.log('The call has been disconnected.', callArg);

			setCall(undefined);
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('cancel', () => {
			console.log('The call has been canceled.');

			setCall(undefined);
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('error', onError);

		newCall.on('mute', value => {
			updateIsMuted(value);
		});

		newCall.on('reconnected', () => {
			console.log('The call has regained connectivity.');
		});

		newCall.on('reconnecting', (twilioError: TwilioError.TwilioError) => {
			// update the UI to alert user that connectivity was lost
			// and that the SDK is trying to reconnect
			// showReconnectingMessageInBrowser();

			// You may also want to view the TwilioError:
			console.log('Connectivity error: ', twilioError);
		});
	};

	const init = async () => {
		const client = new ApiClient(session);
		const result = await client.getTwilioAccessToken();
		if (!result.success) {
			setDevice(undefined);
			return undefined;
		}

		const accessToken = result.value;
		const newDevice = createDevice(accessToken);

		newDevice.on(Device.EventName.TokenWillExpire, async () => {
			console.log('token will expire');
			const tokenResult = await client.getTwilioAccessToken();
			if (tokenResult.success) {
				newDevice.updateToken(tokenResult.value);
			}
		});

		newDevice.on(Device.EventName.Incoming, (incomingCall: Call) => {
			console.log('incoming call', incomingCall);
			addCallListeners(incomingCall);

			if (incomingCall.customParameters.get('autoAnswer') === 'True') {
				incomingCall.accept();
			} else {
				setIsRinging(true);
				const phoneCallId = incomingCall.customParameters.get('phoneCallId');
				if (onIncomingCall) {
					onIncomingCall(phoneCallId);
				}
			}
		});

		newDevice.on(Device.EventName.Error, (twilioError: TwilioError.TwilioError, callArg) => {
			console.log('An error has occurred: ', twilioError, callArg);
			switch (twilioError.code) {
				case 31002:
					// call disconnected
					console.log('call got disconnected');
					hangUp();
					return;

				case 31202:
					// token has expired, auto reconnect
					// Not sure if we need this, I left this here because previously we needed to.
					// Now, the SDK emits tokenWillExpire event and we can update the token there.
					init();
					return;

				default:
					onError(twilioError);
					break;
			}
		});

		newDevice.on('connect', (connection: Call) => {
			console.log('Call is connected', connection);
			if (call !== connection) {
				setCall(connection);
			}
		});

		newDevice.on('disconnect', (connection: Call) => {
			console.log('Call was disconnected', connection);
			if (call === connection) {
				setCall(undefined);
			}
		});

		newDevice.on('offline', () => {
			console.warn('Device is offline');
			deInit();
		});

		newDevice.on('ready', () => {
			console.log('Device is ready');
		});

		// Previously, there was a setup method that would implicitly register the device for inbound calls.
		// With 2.x we need to explicitly call register.
		newDevice.register();

		setDevice(newDevice);

		return newDevice;
	};

	const deInit = () => {
		if (device) {
			try {
				device.disconnectAll();
				device.destroy();
			} catch (ex) {
				console.error('failed to destroy device', ex);
			} finally {
				setDevice(undefined);
			}
		}
	};

	const dial = async (phoneNumber: string, parameters?: Record<string, string>) => {
		let currentDevice = device;
		if (!currentDevice) {
			// will try to initiate implicitly but ...
			// from testing it seems that we would still need to add some delay before trying to make a call or it will fail
			currentDevice = await init();
		}

		if (!currentDevice) {
			console.error('failed to initiate twilio device');
			return false;
		}

		const newCall = await currentDevice.connect({
			params: {
				...parameters,
				To: phoneNumber,
			},
		});

		addCallListeners(newCall);

		return true;
	};

	const hangUp = () => {
		setIsConnected(false);

		if (device) {
			device.disconnectAll();
		}
	};

	const acceptCall = () => {
		console.log('accept call', call, call?.status());
		if (call && call.status() === Call.State.Pending) {
			call.accept();
			return true;
		}

		return false;
	};

	const rejectCall = () => {
		console.log('reject call', call, call?.status());
		if (call && call.status() === Call.State.Pending) {
			call.ignore();
			return true;
		}

		return false;
	};

	const setIsMuted = (value: boolean) => {
		if (call) {
			call.mute(value);
		}
	};

	const sendDigits = (digit: string) => {
		if (!!call && call.status() === Call.State.Open) {
			call.sendDigits(digit);
		}
	};

	return {
		acceptCall,
		call,
		deInit,
		device,
		deviceState,
		dial,
		hangUp,
		init,
		isConnected,
		isMuted,
		isRinging,
		rejectCall,
		sendDigits,
		setIsMuted,
	};
};

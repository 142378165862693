import * as Api from '@ViewModels';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { DialerPhoneCallStatus, useTelephony } from '../../contexts/telephony';
import { CallActions } from './components/CallActions';
import { CallInfo } from './components/CallInfo';
import { CallTimer } from './components/CallTimer';
import { DialerContainer } from './components/DialerContainer';
import { DialerStatus } from './components/DialerStatus';
import { Dialpad } from './components/Dialpad';
import { SplashScreen } from './components/SplashScreen';

const DialerBase: React.FC = () => {
	const {
		acceptCall,
		endCall,
		initiateDialer,
		isMuted,
		isWebDialerEnabled,
		phoneCallStatus,
		rejectCall,
		sendDigits,
		setIsMuted,
		telephonyConfiguration,
		dialerState,
	} = useTelephony();
	const userSession = useUserSession();

	if (!telephonyConfiguration || telephonyConfiguration.provider !== Api.TelephonyServiceProvider.Twilio) {
		return null;
	}

	const isConnected = phoneCallStatus === DialerPhoneCallStatus.Connected;
	const isRinging = phoneCallStatus === DialerPhoneCallStatus.Ringing;

	const handleMuteToggle = () => {
		setIsMuted(!isMuted);
	};

	const handleDialpadChange = (keys: string) => {
		sendDigits(keys);
	};

	if (!isWebDialerEnabled) {
		return <SplashScreen onClick={initiateDialer} />;
	}

	const showDialerStatus =
		!!dialerState && (userSession?.account?.isLevitateSalesCoffeeAccount || userSession?.account?.isRealMagicAccount);

	if (!isConnected && !isRinging && !showDialerStatus) {
		return null;
	}

	const showCallTimer = isConnected || isRinging;

	return (
		<DialerContainer>
			<DialerStatus />
			<CallInfo />
			{showCallTimer ? <CallTimer isConnected={isConnected} /> : null}
			<CallActions
				isConnected={isConnected}
				isMuted={isMuted}
				isRinging={isRinging}
				onClickToAccept={acceptCall}
				onClickToReject={rejectCall}
				onClickToDisconnect={endCall}
				toggleMuted={handleMuteToggle}
			/>
			{isConnected && <Dialpad onKeyPress={handleDialpadChange} />}
		</DialerContainer>
	);
};

export const Dialer = observer(DialerBase);
